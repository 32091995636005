import React, { useState } from "react";
import _ from "lodash";
import { ErrorOutline as ErrorIcon, ChevronRight } from "@material-ui/icons";
import { CalendarMonth } from "@mui/icons-material";
import EntityAttestations from "./EntityAttestations";

const ActionRequired = (props) => {
  function renderEntityAttestations() {
    if (props.page == "dashboard") {
      return _.map(props.stats, (stats, id_340b) => {
        // if (stats.percent_complete == 100) {
        //   return null;
        // }
        function getStyles() {
          if (stats.percent_complete == 100) {
            // return { position: "absolute", visibility: "hidden" };
            return { position: "absolute", left: "-10000px", top: "auto" };
          }
          return null;
        }

        const attestations = props.attestations?.[props.policy]?.[id_340b] || [];
        const entity = props.entities?.find((e) => e.id_340b == id_340b);

        function setEntityAttestations(newAttestations) {
          props.setAttestations({
            ...props.attestations,
            [props.policy]: {
              ...props.attestations[props.policy],
              [id_340b]: newAttestations,
            },
          });
        }

        return (
          <div key={id_340b} style={getStyles()}>
            <EntityAttestations
              page={props.page}
              stats={stats}
              entity={entity}
              userName={props.userName}
              attestations={attestations}
              setAttestations={setEntityAttestations}
              id_340b={id_340b}
              organizationID={props.organizationID}
              setNotice={props.setNotice}
            />
          </div>
        );
      });
    }

    if (props.page == "entities") {
      const attestations = props.attestations?.[props.policy] || [];
      const entity = props.entity;

      function setEntityAttestations(newAttestations) {
        props.setAttestations({
          [props.policy]: newAttestations,
        });
      }

      return (
        <EntityAttestations
          page={props.page}
          stats={props.stats}
          entity={entity}
          userName={props.userName}
          attestations={attestations}
          setAttestations={setEntityAttestations}
          id_340b={entity.id_340b}
          organizationID={props.organizationID}
          setNotice={props.setNotice}
        />
      );
    }
  }

  const getStyles = () => {
    if (props.page == "dashboard" && Object.values(props.stats).every((stat) => stat.percent_complete == 100)) {
      return { position: "absolute", left: "-10000px", top: "auto" };
    }
    return null;
  };

  function allPharmaciesComplete() {
    if (props.page == "dashboard") {
      const result = _.every(props.stats, (stats, _) => stats.percent_complete == 100);
      return result;
    }
    if (props.page == "entities") {
      return props.stats.percent_complete == 100;
    }
  }

  function renderActionRequired() {
    if (allPharmaciesComplete()) {
      return null;
    }
    return <div className="action_required__tag action_required__tag--pulse">Action Required</div>;
  }

  return (
    <>
      <div className="action_required__container" style={getStyles()}>
        <div className="action_required__contents">
          <div className="flex flex-column gap--8">
            {renderActionRequired()}
            <div className="t--md t--500">Sanofi Contract Pharmacy Anti-Diversion Policy</div>

            <div className="t--sm t--subtitle">
              Please provide your contract pharmacy agreement(s) evidencing that you retain legal title to Sanofi
              340B-priced drugs at your Arkansas-based contract pharmacies or, alternatively, an attestation that you
              retain legal title to Sanofi 340B-priced drugs at your Arkansas-based contract pharmacies. Please refer to
              Sanofi’s Policy Document at{" "}
              <a className="t--anchor t--blue" href="/resources" target="_blank">
                340Besp.com/resources
              </a>{" "}
              for additional information.
            </div>
          </div>

          {renderEntityAttestations()}
        </div>
      </div>
    </>
  );
};

export default ActionRequired;
