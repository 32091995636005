import React, { useState } from "react";
import Modal from "../Modal";

const EntityAttestations = ({
  stats,
  id_340b,
  organizationID,
  entity,
  userName,
  attestations,
  setAttestations,
  setNotice,
  page,
}) => {
  const [displayModal, setDisplayModal] = useState(false);

  const statsComplete = stats.percent_complete == 100;

  function renderButton() {
    if (statsComplete) {
      return (
        <div
          className="btn flex align-items-center justify-content-center"
          style={{ alignSelf: "stretch", height: 36 }}
          onClick={() => setDisplayModal(true)}
        >
          Review
        </div>
      );
    } else {
      return (
        <div
          className="btn flex align-items-center justify-content-center"
          style={{ alignSelf: "stretch", height: 36 }}
          onClick={() => setDisplayModal(true)}
        >
          Start
        </div>
      );
    }
  }

  function renderEntityId() {
    if (page == "dashboard") {
      return <div className="t--500 t--md">{id_340b}</div>;
    }
    return null;
  }

  function renderDaysLeft() {
    if (statsComplete) {
      return "All Pharmacies Complete";
    }

    if (!stats.days_left) {
      function pluralizePharmacyIfNeeded() {
        if (stats.attestations_remaining > 1) {
          return "Pharmacies";
        }
        return "Pharmacy";
      }

      return `${stats.attestations_remaining} ${pluralizePharmacyIfNeeded()} Remaining`;
    }

    return `${stats.days_left} Days Left to Submit`;
  }

  return (
    <>
      <div className="action_required__stats-container">
        {renderEntityId()}
        <div>
          {/* <div className="t--md t--500 t--sm mb--4">{stats.attestations_remaining} Pharmacies Remaining</div> */}
          <div className="t--sm t--subtitle">{renderDaysLeft()}</div>
          <div className="flex justify-content-end">
            <div className="t--500 t--md mb--4">
              {/* <span className="t--sm t--subtitle">Pharmacies Remaining: </span> */}
              {`${stats.attestations_complete}/${stats.all_attestations}`}
            </div>
          </div>
          <div className="progress_bar__container mb--4">
            <div className="progress_bar__fill" style={{ width: `${stats.percent_complete}%` }} />
          </div>
        </div>
        {renderButton()}
      </div>

      {displayModal && (
        <Modal
          page={page}
          statsComplete={statsComplete}
          setDisplay={setDisplayModal}
          dueDate={stats.due_date}
          organizationID={organizationID}
          id_340b={id_340b}
          entity={entity}
          userName={userName}
          attestations={attestations}
          setAttestations={setAttestations}
          setNotice={setNotice}
        />
      )}
    </>
  );
};

export default EntityAttestations;
